<!-- 投资人关系 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text20 }}</h3>
      </div>
    </div>
    <div class="container">
      <div class="container_top">
        <div class="box">
          <div class="box_left">
            <h3>{{ text1 }}</h3>
          </div>
          <div class="box_right">
            <h4>{{ text2 }}</h4>
            <p>{{ text3 }}</p>
            <h4 class="two">{{ text4 }}</h4>
            <p>{{ text5 }}</p>
          </div>
        </div>
      </div>
      <div class="container_conter">
        <div class="conter_left">
          <h3>{{ text6 }}</h3>
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/touzi/center.png" alt="" />
        </div>
        <div class="conter_right">
          <h4 class="one">{{ text7 }}</h4>
          <p>
            {{ text8 }}
          </p>
          <h4>{{ text9 }}</h4>
          <p>
            {{ text10 }}
          </p>
          <h4>{{ text11 }}</h4>
          <p>
            {{ text12 }}
          </p>
          <h4>{{ text13 }}</h4>
          <p>
            {{ text14 }}
          </p>
          <h4>{{ text15 }}</h4>
          <p>
            {{ text16 }}
          </p>
          <h4>{{ text17 }}</h4>
          <p>
            {{ text18 }}
          </p>
        </div>
      </div>
      <div class="container_bottom">{{ text19 }}</div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "明济生物融资情况",
          text2: "2018.03完成A轮融资",
          text3: "本轮融资由礼来亚洲基金领投",
          text4: "2021.03完成B轮融资",
          text5:
            "本轮融资总规模3亿人民币，由中金资本旗下中金启德基金领投，现有投资方礼来亚洲基金继续追加投资，同时国药资本、德屹资本及和玉资本（MSA Capital）等机构参与跟投。华兴资本担任本轮交易的独家财务顾问",
          text6: "投资人信息",
          text7: "中金资本",
          text8:
            "中金资本是中国国际金融有限公司唯一的私募投资基金业务平台，统一管理中金公司境内外私募投资基金业务；中金资本整体管理资产规模超过3800亿元人民币，拥有超过300人的专业投资管理团队，是国内规模最大且领先的私募股权投资基金管理人之一",
          text9: "礼来亚洲基金",
          text10:
            "礼来亚洲基金2008年起始于礼来制药的风险投资部门，迄今已发展成业界具有领导力的生命科学和医疗健康风险投资基金。其团队秉承正直、创新和合作的理念，由超过30名卓有成绩的科学研究、临床医学、投资管理和运营专业人才组成。目前一共管理超过等值45亿美元的6支美元基金和5支人民币基金礼来亚洲基金深耕中国市场，在临床前和临床开发、监管政策、商业市场等方面拥有丰富经验",
          text11: "国药资本",
          text12:
            "国药资本管理有限公司是国药集团发起设立的专注于医疗健康领域的专业股权投资机构，管理团队由国药集团内外部专业投资人士按照市场化机制组成。对医疗健康行业发展、产业运营和资本运作理解深刻，能有效整合国药集团内外部行业资源和资本力量",
          text13: "德屹资本",
          text14:
            "德屹资本创立于2016年6月，是经中国证券投资基金业协会备案的私募基金管理人。德屹资本专注于现代服务业，优先投向医疗健康、信息服务、消费升级等领域。汇集了专注于现代服务业的诸多产业界和投资界精英，结合已有的行业积累和投资布局，在各细分垂直领域进一步挖掘投资机会，提升投资价值",
          text15: "和玉资本",
          text16:
            "和玉资本（MSA Capital）是一家深耕中国、放眼全球的私募股权投资机构，管理规模约15亿美元，受托管理的资金主要来自全球顶尖的主权财富基金、国际资产管理公司、养老基金、大学捐赠基金、母基金、家族基金，以及中国著名的互联网和生物科技企业家领袖。和玉资本（MSA Capital）始终专注深入的分析和自上而下的研究，对投资生态系统的各环节拥有丰富深刻的见解和实践，已完成约100笔TMT及医疗行业的投资",
          text17: "华兴资本",
          text18:
            "华兴资本集团成立于2005年，当前公司业务包括私募融资、并购、证券发行与承销、证券研究与交易、投资管理及其他服务。截至2021年6月30日，华兴资本集团投资银行、投资管理及华兴证券累计参与、投资的交易数量超1,125笔，总交易金额超1.4万亿人民币，私募股权投资基金资产管理规模超610亿人民币",
          text19: "药济德来和金玉，明礼兴国中华屹",
          text20: "投资人信息披露",
        };
        break;
      case "en":
        return {
          text1: "Financing of FutureGen Biopharm",
          text2: "A Round Financing was completed in March 2018",
          text3: "The round of financing was led by Lilly Asia Ventures.",
          text4: "B Round Financing was completed in March 2021. ",
          text5:
            "The total amount of this round of financing was CNY 300 million, led by CICC Qide Fund under CICC Capital. The existing investor LAV will continue to invest, while Sinophem Capital, DYEE Capital, MSA Capital and other institutions will follow the investment. China Renaissance acted as the exclusive financial advisor for this round of transaction. ",
          text6: "Investor information",
          text7: "CICC Capital",
          text8:
            "CICC Capital is the only private equity investment fund business platform of CICC, which is responsible for the unified management of CICC's domestic and foreign private equity investment fund business. CICC Capital is one of the largest and leading private equity fund managers in China with the total assets under management of more than CNY 380 billion and a professional investment management team of more than 300 people.",
          text9: "Lilly Asia Ventures (LAV)",
          text10:
            "Starting with the venture capital section of Eli Lilly in 2008, Lilly Asia Ventures has grown into the industry's leading life sciences and healthcare venture fund. Upholding integrity, innovation and collaboration, its team consists of more than 30 highly accomplished professionals in scientific research, clinical medicine, investment management and operations. Currently, LAV manages 6 US dollar funds and 5 RMB funds worth more than 4.5 billion US dollars. LAV is deeply engaged in China market, and has rich experience in preclinical and clinical development, regulatory policy, and commercial market.",
          text11: "Sinopharm Capital",
          text12:
            "Sinopharm Capital Management Co., Ltd. (Sinopharm Capital) is a professional equity investment institution specialized in healthcare that is initiated and established by Sinopharm Group. The management team is composed of internal and external investment professionals of Sinopharm Group in accordance with the market-oriented mechanism. Based on the deep understanding of healthcare industry development, industry operation and capital operation, it can effectively integrate the internal and external industry resources and capital forces of Sinopharm Group. ",
          text13: "DYEE Capital",
          text14:
            "DYEE Capital, founded in June 2016, is a private equity fund manager registered with Asset Management Association of China. DYEE Capital focuses on modern service industry, giving priority to healthcare, information services, consumption upgrading and other fields. It has brought together many industries and investment elites specialized in modern service industry, integrated the existing industrial accumulation and investment layout, and excavated investment opportunities in various subdivided vertical fields to enhance investment value.",
          text15: "MSA Capital",
          text16:
            "MSA Capital, a private equity investment institution deeply engaged in China with a global perspective, has a management scale of about 1.5 billion US dollars, and its entrusted funds are mainly from the world's top sovereign wealth funds, international asset management companies, pension funds, university endowments, mother funds and family funds, as well as China's leading Internet and biotech entrepreneurs. Focused on in-depth analysis and top-down research, MSA Capital has rich insights and practices in all aspects of the investment ecosystem, and has completed about 100 TMT and healthcare investments.",
          text17: "China Renaissance",
          text18:
            "Founded in 2005, China Renaissance's current business includes private equity financing, mergers and acquisitions, securities issuance and underwriting, securities research and trading, investment management and other services. As of June 30, 2021, China Renaissance Investment Bank, Investment Management and China Renaissance Securities have participated in and invested in more than 1,125 transactions, with a total transaction value of more than CNY 1.4 trillion, and private equity fund asset management scale of more than CNY 61 billion.",
          text19: "Sinopharm, FutureGen Biopharm, DYEE Capital, MSA Capital, CICC Capital, China Renaissance and LAV will work together to boost Chinese pharmaceutical industry.",
          text20: "Disclosure of investor information",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>
<style lang="less" scoped>
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/touzi/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.container {
  .container_top {
    background-color: rgba(204, 204, 204, 0.2);
    .box {
      width: 100%;
      max-width: 1200px;
      margin: 0px auto;
      color: #203864;
      box-sizing: border-box;
      padding-top: 150px;
      padding-bottom: 150px;
      display: flex;
      justify-content: space-between;
      .box_left {
        width: 40vw;
        h3 {
          position: relative;
          font-size: 50px;
        }
        h3::after {
          position: absolute;
          top: 130%;
          left: 0%;
          width: 50px;
          height: 2px;
          content: "";
          opacity: 0.6;
          background-color: #203864;
        }
      }
      .box_right {
        width: 60vw;
        h4 {
          font-size: 30px;
          font-weight: normal;
        }
        .two {
          margin-top: 40px;
        }
        p {
          font-size: 18px;
          line-height: 36px;
          margin-top: 20px;
          color: #4a4a4a;
        }
      }
    }
  }
  .container_conter {
    width: 100%;
    max-width: 1570px;
    color: #203864;
    margin-top: 150px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    .conter_left {
      width: 50vw;
      h3 {
        position: relative;
        font-size: 50px;
        text-align: right;
        margin-right: 130px;
        margin-bottom: 100px;
      }
      h3::after {
        position: absolute;
        top: 130%;
        right: 30%;
        width: 50px;
        height: 2px;
        content: "";
        opacity: 0.6;
        background-color: #203864;
      }
      img {
        width: 660px;
        height: 1006px;
      }
    }
    .conter_right {
      width: 55vw;
      h4 {
        font-size: 30px;
        font-weight: normal;
        margin-top: 40px;
      }
      .one {
        margin-top: 0px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        margin-top: 4px;
        color: #4a4a4a;
      }
    }
  }
  .container_bottom {
    width: 100%;
    max-width: 1200px;
    height: 82px;
    margin: 0 auto;
    padding-bottom: 150px;
    font-size: 30px;
    color: #fff;
    text-align: center;
    line-height: 82px;
    background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/touzi/bottom.png") no-repeat;
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .container {
    .container_top {
      .box {
        padding: 20px;
        flex-direction: column;
        justify-content: flex-start;
        .box_left {
          h3 {
            font-size: 30px;
            width: 90vw;
            font-weight: normal;
          }
          h3::after {
            top: 120%;
            width: 30px;
          }
        }
        .box_right {
          h4 {
            font-size: 24px;
            margin-top: 40px;
          }
          .two {
            margin-top: 30px;
          }
          p {
            font-size: 14px;
            line-height: 24px;
            margin-top: 8px;
          }
        }
      }
    }
    .container_conter {
      padding: 20px;
      margin-top: 0px;
      margin-bottom: 0px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: flex-start;
      .conter_left {
        h3 {
          width: 90vw;
          font-size: 30px;
          text-align: left;
          margin-bottom: 30px;
          font-weight: normal;
        }
        h3::after {
          left: 0%;
          width: 30px;
        }
        img {
          display: none;
        }
      }
      .conter_right {
        width: 90vw;
        h4 {
          font-size: 24px;
        }
        .one {
          margin-top: 0px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
          margin-top: 10px;
        }
      }
    }
    .container_bottom {
      padding-bottom: 20px;
      font-size: 20px;
    }
  }
}
</style>
